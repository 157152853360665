import HeroImage from '@/components/image/HeroImage'
import { styled } from 'stitches.config'

const Content = styled('div', {
  height: '100%',
  position: 'absolute',
  top: 0,
  width: '100%',
  zIndex: 1, 
  display:'flex',
  alignItems:'flex-end'
})

const HeroBackgroundImage = ({ className, style = {}, imageProps = {}, children }) => {
  return (
    <div className={className} style={{ ...styles.container, ...style }}>
      <HeroImage
        layout="fill"
        objectFit="cover"
        {...imageProps}
        style={{ ...styles.image, ...imageProps.style }}
      />
      {children && <Content>{children}</Content>}
    </div>
  )
}

export default HeroBackgroundImage

const styles = {
  container: {
    position: 'relative',
  },
  image: {
    height: '100%',
    width: '100%',
    zIndex: 0,
  },
}
