import { useState, useEffect, useRef } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { enableBodyScroll } from '@/lib/helpers'
import { addForwardSlash } from '@/lib/helpers'
import MobileSubSubMenu from './mobile-sub-menu'
import { navClickGTM } from '@/lib/gtm'
import Icon from '@/components/icon/icon'

const MobileSubMenu = (props) => {
  const [isOpened, setIsOpened] = useState(false)
  const [menuHeight, setMenuHeight] = useState('auto')
  const submenuRef = useRef(null)

  const router = useRouter()

  const closeAndRedirect = (e, url) => {
    //GTM
    navClickGTM(props?.urlDefault, props?.titleDefault, props?.titleDefault, 'header')

    e.preventDefault()
    props.setIsOpened(false)
    enableBodyScroll()
    if (props.setMounted) {
      props.setMounted(false)
    }
    router.push(addForwardSlash(url))
  }

  useEffect(() => {

    if (isOpened) {
      if (submenuRef.current) {
        props.setMenuHeight(`${submenuRef.current.offsetHeight}px`)
        setMenuHeight(`${submenuRef.current.offsetHeight}px`)
      }

    } else {
      props.setMenuHeight('auto')
    }

    return () => {
      props.setMenuHeight('auto')
    }
  },[isOpened])

  useEffect(() => {
    //resets the L2's when main nav is closed
    if (!props.isParentOpen) {
      setIsOpened(false)
    }
  },[props.isParentOpen])

  return (
    <>
      <nav className="mobile-nav-menu-l1">
        <div
          className={`flex justify-left bg-white items-center pl-25 pr-31s cursor-pointer text-black ${
            props?.navigationAction || !isOpened ? 'mob-menu-block' : 'mob-menu-link-open'
          }
             ${!props?.isLast ? 'border-b border-borderGray' : ''}`}
          onClick={() => {
            if (props?.navigationAction) {
              router.push(addForwardSlash(props?.url))
              props.setIsOpened(false)
            } else {
              setIsOpened(!isOpened)
            }
          }}
        >
          <div className={`w-full py-3 h-full flex items-center justify-between font-primary`}>
            <span
              className={`mobile-nav-menu-l1-label font-normal uppercase leading-14`}
            >
              {props.title}
            </span>
            <Icon
              className={`flex-shrink-0`}
              viewBox={`0 0 12 12`}
              size={12}
              fill="#E36F22"
              icon="arrow_right"
            />
          </div>
        </div>
        {
          isOpened && (
            <div ref={submenuRef} className="absolute left-0 top-0 right-0 bg-white text-black z-10">
              <div className="flex justify-left bg-white items-center pl-25 pr-31s cursor-pointer">
                <div className={`mobile-nav-menu-back w-full py-5 h-full inline-flex items-center font-primary`}  onClick={() => setIsOpened(false)}>
                  <Icon
                    className={`flex-shrink-0`}
                    viewBox={`0 0 12 12`}
                    size={12}
                    fill="#00000"
                    icon="arrow_left"
                  />
                  <span
                    style={{ borderBottom: '4px solid #e36f22', paddingBottom: '5px' }}
                    className={`ml-5 text-base font-normal uppercase leading-14 mobile-menu-link`}
                  >
                    {props.title}
                  </span>
                </div>
              </div>
              <div className="mobile-nav-menu-l2-wrap ">
                {props.submenus &&
                  props.submenus.fields &&
                  props.submenus.fields.firstColumn &&
                  props.submenus.fields.firstColumn.map((item, index) => {
                    return item?.fields && (
                      <MobileSubSubMenu
                        item={item}
                        key={index}
                        setIsOpened={props.setIsOpened}
                        isOpened={isOpened}
                        parentTitle={props?.titleDefault}
                        setMenuHeight={props?.setMenuHeight}
                        menuHeight={menuHeight}
                      />
                    )
                  })}
                {props.submenus &&
                  props.submenus.fields &&
                  props.submenus.fields.secondColumn &&
                  props.submenus.fields.secondColumn.map((item, index) => {
                    return item?.fields && (
                      <MobileSubSubMenu
                        item={item}
                        key={index}
                        setIsOpened={props.setIsOpened}
                        isOpened={isOpened}
                        parentTitle={props?.titleDefault}
                        setMenuHeight={props?.setMenuHeight}
                        menuHeight={menuHeight}
                      />
                    )
                  })}
                {props.submenus &&
                  props.submenus.fields &&
                  props.submenus.fields.thirdColumn &&
                  props.submenus.fields.thirdColumn.map((item, index) => {
                    return item?.fields && (
                      <MobileSubSubMenu
                        item={item}
                        key={index}
                        setIsOpened={props.setIsOpened}
                        isOpened={isOpened}
                        parentTitle={props?.titleDefault}
                        setMenuHeight={props?.setMenuHeight}
                        menuHeight={menuHeight}
                      />
                    )
                  })}
                {props.submenus &&
                  props.submenus.fields &&
                  props.submenus.fields.fourthColumn &&
                  props.submenus.fields.fourthColumn.map((item, index) => {
                    return item?.fields && (
                      <MobileSubSubMenu
                        item={item}
                        key={index}
                        setIsOpened={props.setIsOpened}
                        isOpened={isOpened}
                        parentTitle={props?.titleDefault}
                        setMenuHeight={props?.setMenuHeight}
                        menuHeight={menuHeight}
                      />
                    )
                  })}
                {props.submenus &&
                  props.submenus.fields &&
                  props.submenus.fields.fifthColumn &&
                  props.submenus.fields.fifthColumn.map((item, index) => {
                    return item?.fields && (
                      <MobileSubSubMenu
                        item={item}
                        key={index}
                        setIsOpened={props.setIsOpened}
                        isOpened={isOpened}
                        parentTitle={props?.titleDefault}
                        setMenuHeight={props?.setMenuHeight}
                        menuHeight={menuHeight}
                      />
                    )
                  })}
                {props.submenus &&
                  props.submenus.fields &&
                  props.submenus.fields.sixthColumn &&
                  props.submenus.fields.sixthColumn.map((item, index) => {
                    return  item?.fields &&(
                      <MobileSubSubMenu
                        item={item}
                        key={index}
                        setIsOpened={props.setIsOpened}
                        isOpened={isOpened}
                        parentTitle={props?.titleDefault}
                        setMenuHeight={props?.setMenuHeight}
                        menuHeight={menuHeight}
                      />
                    )
                  })}
              </div>

            </div>
          )
        }
      </nav>
      <style jsx>
        {`
          .mobile-nav-menu-l1-label {
            font-size: 16px;
          }

          .mobile-nav-menu-l1-link-label {
            font-size: 14px;
            line-height: 12px;
          }

          .mobile-menu-link {
            bottom: 21px;
          }

          .mob-menu-link-open {
            height: 80px;
          }

        `}
      </style>
    </>
  )
}

export default MobileSubMenu
